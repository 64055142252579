<template>
  <div class="vx-row" id="faq-page">
    <div class="vx-col w-full">
        <FAQ/>

      <p class="more-info">
        For more information, refer to our
        <router-link :to="{ name: 'Support' }">support training section.</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import FAQ from '../../../components/faq/FAQ.vue';
export default {
  components: { FAQ },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "./faq.scss";
</style>
